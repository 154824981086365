/* slick.css */

.dots_custom_main {
  width: 100%;
  height: 10px;
  display: flex;
  vertical-align: middle;
  justify-content: left;
  margin: auto 0;
  padding: 0;
  margin-top: 16px;
  margin-left: 24px;
}

.dots_custom_main li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 4px;
  padding: 0;
}

.dots_custom_main li button {
  border: none;
  background: #595959;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  padding: 0;
  transition: 0.5s;
}

.dots_custom_main li.slick-active button {
  background-color: #ffffff;
  width: 25px;
  transition: 0.5s;
}
